import React from 'react';
import { Listbox } from '@headlessui/react';
import Icon from '../Icon';
import { InputContainer } from './InputContainer';
import { useTranslation } from 'react-i18next';
import { Label } from './Label';
import SelectAutocomplete from './SelectAutocomplete';
import { SelectContainer, SelectContainerProps } from './SelectContainer';

export interface SelectProps<T> extends SelectContainerProps {
  id?: string;
  label?: string;
  options: T[];
  value?: T | undefined;
  setValue: React.Dispatch<React.SetStateAction<T | undefined>>;
  displayValue?: (option: T) => string | undefined;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  nullable?: boolean;
  autocomplete?: boolean;
  showRemark?: boolean;
  clearable?: boolean;
}

export function Select<T>(props: SelectProps<T>) {
  const {
    id,
    label,
    options,
    value,
    setValue,
    displayValue = (option: T) => `${option}`,
    placeholder,
    disabled,
    required,
    autocomplete,
    fullWidth,
    minimal,
    showRemark,
    clearable,
  } = props;

  const { t } = useTranslation('common');

  if (autocomplete) return <SelectAutocomplete<T> {...props} />;

  const noOptionsOption = (
    <div className="select-option nothing-found">
      <Icon type="Unhappy" color="grey400" />
      {t('common:noOptions')}
    </div>
  );

  const clearButton = (
    <button
      className="clear-select-button"
      onClick={e => {
        e.stopPropagation();
        setValue(undefined);
      }}
    >
      <Icon type="Close" size={minimal ? 12 : undefined} />
    </button>
  );

  return (
    <SelectContainer
      id={id}
      data-testid={id}
      fullWidth={fullWidth}
      minimal={minimal}
      onClick={e => e.stopPropagation()}
    >
      <Listbox value={value} onChange={setValue} disabled={disabled}>
        <InputContainer fullWidth={fullWidth} minimal={minimal}>
          {label && (
            <Listbox.Label>
              <Label
                disabled={disabled}
                className={required ? 'is-required' : ''}
              >
                {label}
              </Label>
            </Listbox.Label>
          )}

          {showRemark && (
            <Icon
              type="ExclamationTriangle"
              color="error"
              size={14}
              className="remark-icon"
            />
          )}

          {!!value && clearable && clearButton}

          <Listbox.Button
            className={`button-as-input ${disabled ? 'is-disabled' : ''}`}
          >
            {({ open }) => (
              <>
                {value !== null && value !== undefined
                  ? displayValue(value)
                  : placeholder ?? `${t('action:select')}...`}
                <Icon
                  type={open ? 'ChevronUp' : 'ChevronDown'}
                  color={minimal ? 'grey300' : 'secondary'}
                  size={minimal ? 10 : undefined}
                />
              </>
            )}
          </Listbox.Button>
        </InputContainer>

        <Listbox.Options className="select-options">
          {!options.length && noOptionsOption}

          {options.map((option, i) => (
            <Listbox.Option
              key={(option as any).id ?? i}
              value={option}
              className={({ selected }) =>
                `select-option ${selected ? 'is-selected' : ''}`
              }
            >
              {({ selected }) => (
                <>
                  {selected && (
                    <Icon
                      type="Selected"
                      color="primary"
                      size={minimal ? 12 : undefined}
                    />
                  )}
                  {displayValue(option)}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </SelectContainer>
  );
}

export default Select;
