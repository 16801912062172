import React from 'react';
import styled from 'styled-components';
import { Disclosure } from '@headlessui/react';
import Icon from './Icon';

const FADED_COLOR = 'grey300';

interface StyledAccordionProps {
  variant?: 'default' | 'faded';
  noBorder?: boolean;
}

const AccordionContainer = styled.div<StyledAccordionProps>`
  padding: ${p => p.theme.spacing.md} 0;
  border-bottom: ${p =>
    p.noBorder ? undefined : `1px solid ${p.theme.color.grey300}`};

  .accordion-title-row {
    display: flex;
    align-items: center;
  }

  .accordion-button {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    > svg {
      margin-right: ${p => p.theme.spacing.md};
    }

    .accordion-title,
    .accordion-title > h1,
    .accordion-title > h2,
    .accordion-title > h3,
    .accordion-title > h4,
    .accordion-title > h5,
    .accordion-title > h6 {
      flex: 1;
      text-align: left;
      margin: 0;
      color: ${p =>
        p.variant === 'faded' ? p.theme.color[FADED_COLOR] : undefined};
    }
  }

  .accordion-panel {
    padding-top: 0.5rem;
    padding-left: 1.1rem;
  }
`;

interface AccordionProps extends StyledAccordionProps {
  id?: string;
  title: string | JSX.Element;
  sideContent?: boolean | string | JSX.Element;
  defaultOpen?: boolean;
  className?: string;
  onClick?: (isOpen: boolean) => void;
  showRemark?: boolean;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({
  id,
  title,
  sideContent,
  defaultOpen,
  className,
  onClick,
  variant,
  noBorder,
  showRemark,
  children,
}) => {
  return (
    <AccordionContainer
      id={id}
      data-testid={id}
      variant={variant}
      className={`accordion ${className}`}
      noBorder={noBorder}
    >
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <div className="accordion-title-row">
              <Disclosure.Button
                className="accordion-button"
                data-testid={`${id}-button`}
                onClick={() => onClick && onClick(!open)}
              >
                <Icon
                  type={open ? 'ChevronUp' : 'ChevronDown'}
                  color={variant === 'faded' ? FADED_COLOR : 'secondary'}
                />
                {showRemark && (
                  <Icon type="ExclamationTriangle" color="error" />
                )}
                <div className="accordion-title" data-testid={`${id}-title`}>
                  {title}
                </div>
              </Disclosure.Button>

              {sideContent}
            </div>

            <div>
              <Disclosure.Panel
                className="accordion-panel"
                data-testid={`${id}-content`}
              >
                {children}
              </Disclosure.Panel>
            </div>
          </>
        )}
      </Disclosure>
    </AccordionContainer>
  );
};

export default Accordion;
